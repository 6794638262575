import React from 'react';
import PlacesSearchBar from '../Components/PlacesSearchBar';
import AppleCheckoutComponent from '../Components/AppleCheckoutComponent';
import GoogleCheckoutComponent from '../Components/GoogleCheckoutComponent';
import AppointmentTime from '../Components/AppointmentTime';
import { Box, Button, Container, Fade } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { PlaceContext } from '../Context/PlaceContext';
import { BookingContext } from '../Context/BookingContext';
import AcceptTerms from '../Components/AcceptTerms';
import { TermsContext } from '../Context/TermsContext';
import { CalendarIcon } from '@mui/x-date-pickers';

// Test iframe:
//  <iframe allow='payment;geolocation;' height={'500'} width={'500'} src='https://point.rocks/Zahnarzt'></iframe>

const API = process.env.REACT_APP_API as string;

const Home: React.FC = () => {

  const { t } = useTranslation();
  const { selectedPlace } = React.useContext(PlaceContext);
  const { selectedBooking } = React.useContext(BookingContext);
  const { acceptedTerms } = React.useContext(TermsContext);

  const {query = ''} = useParams<{ query?: string; }>();

  return (
    <div className="content">
      <Helmet>
        <title>{query ? t("Book appointment at ") + query: t("Book Appointment") }</title>
        <meta name="description" content={t("Book appointment at ") + `${query}`} />
        <meta name="keywords" content={t("Appointment") + `, ${query}`} />
      </Helmet>
      <Container>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <p className='home-title'>
            {t("Book Appointment")}
          </p>
        <PlacesSearchBar q={query}/>
        <Fade in={selectedPlace != null} timeout={800} >
          <div>
            <AppointmentTime />
            <AcceptTerms />
            {!selectedBooking && acceptedTerms && <AppleCheckoutComponent/>}
            {!selectedBooking && acceptedTerms && <GoogleCheckoutComponent/>}
            {selectedBooking && <p style={{ textAlign:'center' }} >{t("Thank you for booking an appointment. Please check your mailbox.")}</p>}

            {selectedBooking && <Link to={API + '/ical?caldata=' + JSON.stringify(selectedBooking)} download target='_blank'>
              <Button
                variant="contained"
                sx={{ mb: 2, borderRadius: '10px', minWidth: '240px' }}
                startIcon={<CalendarIcon/>}
              >
              {t('Add to calendar')}
              </Button>
            </Link> }
          </div>
        </Fade>
        </Box>
      </Container>
    </div>
  );
};

export default Home;
