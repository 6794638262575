import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

const APP_NAME = process.env.REACT_APP_NAME as string;

const Terms: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className='plaintext'>
      <Helmet>
        <title>{t('Terms') + " - " + APP_NAME}</title>
      </Helmet>
      <h1>{t('Terms')}</h1>
      <div dangerouslySetInnerHTML={{__html: t('TERMS')}} ></div>
    </div>
  );
};

export default Terms;
