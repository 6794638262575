import React from 'react';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useTranslation } from 'react-i18next';
import { DateTimeContext } from '../Context/DateTimeContext';
import dayjs, { Dayjs } from 'dayjs';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import i18n from '../i18n';
import 'dayjs/locale/de';
import 'dayjs/locale/en';
import { PlaceContext } from '../Context/PlaceContext';

const AppointmentTime: React.FC = () => {
  const { t } = useTranslation();
  const { selectedDateTime, setSelectedDateTime } = React.useContext(DateTimeContext);
  const { selectedPlace } = React.useContext(PlaceContext);
  const [placeid, setplaceid] = React.useState<string | null>(null);

  React.useEffect(() => {

    if (!selectedDateTime) {

      let tm = dayjs();
      tm = tm.set('second', 0);
      tm = tm.set('millisecond', 0);
      tm = tm.add(5,'minute');
      if (tm.minute() <= 30) {
        tm = tm.set('minute', 30);
      } else {
        tm = tm.set('minute', 0);
        tm = tm.add(1, 'hour');
      }

      setSelectedDateTime(tm);
    }

    if (selectedPlace && selectedPlace?.place_id !== placeid && selectedPlace?.nextAvailableDate) {

      var tm = dayjs( selectedPlace.nextAvailableDate );
      setSelectedDateTime(tm);
      setplaceid(selectedPlace.place_id);
    }

  }, [selectedDateTime, setSelectedDateTime, setplaceid, placeid, selectedPlace])

  const handleDateChange = (newValue: Dayjs | null) => {
    setSelectedDateTime(newValue);
  };

  const isDateTimeDisabled = (date: Dayjs, isdate: boolean) => {
    // console.log("isDateTimeDisabled: " , date);
    // Example: Disable all dates before today and after 30 days from today
    let ret = false;
    const now = dayjs();
    const maxDate = now.add(365, 'day');

    ret = date.isBefore(now) || date.isAfter(maxDate);

    // open 24/7 ?
    let twentyfourseven = false;
    if (selectedPlace?.regularOpeningHours && selectedPlace?.regularOpeningHours.periods) {
      // console.log("selectedPlace?.regularOpeningHours: ", selectedPlace?.regularOpeningHours);
      if (selectedPlace?.regularOpeningHours.periods.length === 1) {
        if (selectedPlace?.regularOpeningHours.periods[0].open && !selectedPlace?.regularOpeningHours.periods[0].close) {
          twentyfourseven = true;
        }
      }
    }
    // console.log("24/7: ", twentyfourseven);

    if (!ret && !twentyfourseven && selectedPlace && (selectedPlace.regularOpeningHours || selectedPlace.currentOpeningHours || selectedPlace.regularSecondaryOpeningHours )) {
      
      // Sunday(0)  - Saturday(6), day of the week
      // https://day.js.org/docs/en/get-set/day

        let datefound = false;
        var allHours = [
          ...(selectedPlace.currentOpeningHours?.periods || []),
        ];
      
        if (selectedPlace.regularSecondaryOpeningHours) {
          for (const hours of selectedPlace.regularSecondaryOpeningHours) {
            allHours = [...allHours, ...hours.periods];
          }
        }
      
        allHours = [...allHours, ...(selectedPlace.regularOpeningHours?.periods || [])];

        for (const hours of allHours) {

          if ((hours.open.day%7) === date.day()) {

            let opentime = hours.open.time as number / 100;
            let closetime = hours.close.time as number / 100;
            // console.log("times: ", date.day(), date.hour(), opentime, closetime);
            if (isdate || ((opentime <= date.hour()) && (closetime > date.hour()))) {

                  datefound = true;
                  break;
            }
          }

        };

        ret = !datefound;
    }

    return ret;
  };

  const isDateDisabled = (date: Dayjs) => {
    return isDateTimeDisabled(date, true);
  }
  const isTimeDisabled = (date: Dayjs) => {
    return isDateTimeDisabled(date, false);
  }

  return (
    <div className="date-time-picker">
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.resolvedLanguage} >
      <DateTimePicker
        label={t('Date & Time')}
        value={selectedDateTime ? dayjs(selectedDateTime) : null}
        onChange={handleDateChange}
        shouldDisableDate={isDateDisabled}
        shouldDisableTime={isTimeDisabled}
        closeOnSelect
        disablePast
        minutesStep={30}
        displayWeekNumber
        showDaysOutsideCurrentMonth
        formatDensity='dense'
        viewRenderers={{
          hours: renderTimeViewClock,
          minutes: renderTimeViewClock
        }}
      />
    </LocalizationProvider>
    </div>
  );
};

export default AppointmentTime;
