import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

const APP_NAME = process.env.REACT_APP_NAME as string;

const Imprint: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className='plaintext'>
      <Helmet>
        <title>{t('Imprint') + " - " + APP_NAME}</title>
      </Helmet>
      <h1>{t('Imprint')}</h1>
      <div dangerouslySetInnerHTML={{__html: t('IMPRINT')}} ></div>
    </div>
  );
};

export default Imprint;
