import React from 'react';

export interface BookingType {
  gpid: string | undefined | null;
  starttime: string | undefined | null;
  email: string | undefined | null;
  name: string | undefined | null;
}

interface BookingContextProps {
  selectedBooking: BookingType | null;
  setSelectedBooking: (book: BookingType | null) => void;
}

export const BookingContext = React.createContext<BookingContextProps>({
    selectedBooking: null,
    setSelectedBooking:() => {}
});

export const BookingProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [selectedBooking, setSelectedBooking] = React.useState<BookingType | null>(null);

  return (
    <BookingContext.Provider value={{ selectedBooking, setSelectedBooking }}>
      {children}
    </BookingContext.Provider>
  );
};
