import React from 'react';
import { useTranslation } from 'react-i18next';
import loadScript from '../Tools/loadScript';
import axios from 'axios';
import Box from '@mui/material/Box';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import { DateTimeContext } from '../Context/DateTimeContext';
import { PlaceContext, PlaceType } from '../Context/PlaceContext';
import { BookingContext, BookingType } from '../Context/BookingContext';
import { CircularProgress, Container } from '@mui/material';

const API = process.env.REACT_APP_API as string;

interface State extends SnackbarOrigin {
  open: boolean;
  message: string;
}

export type ApplePaySession = any;

interface ApplePayButtonProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {

  buttonstyle:string|undefined;
  type:string|undefined;
  locale:string|undefined;
};

declare global {
  namespace JSX {
    interface IntrinsicElements {
      ["apple-pay-button"]: ApplePayButtonProps;
    }
  }
}

declare const window: {
  ApplePaySession:any;
  ApplePayPaymentMethodUpdate:any;
};

const AppleCheckoutComponent: React.FC = () => {

  const { i18n } = useTranslation();
  const [appleActivated, setAppleActivated] = React.useState(false);

  const { selectedDateTime } = React.useContext(DateTimeContext);
  const { selectedPlace } = React.useContext(PlaceContext);
  const { setSelectedBooking } = React.useContext(BookingContext);
  const [loading, setLoading] = React.useState(false);
  const buttonActivated = React.useRef(false);
  const place = React.useRef<PlaceType|null>(null);

  // Snackbar
  const [state, setState] = React.useState<State>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
    message: '',
  });
  const { vertical, horizontal, open } = state;

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const validateMerchant = async (url:string) => {
    try {
      const response = await axios.post(API + '/authorizeMerchant', {});
      console.log('Merchant validation response: ', response);
      return response.data.merchantSession;
    } catch (error) {
      console.error('Merchant validation failed:', error);
      throw new Error('Merchant validation failed');
    }
  };

  async function sendAppointment (data:any) {

    setLoading(true);

    let contact:any = {
      sender: {
        provider: "Apple",
        apiversion: data.token.paymentData.version,
        tokenization: data.token,
        name: data.shippingContact.givenName + " " + data.shippingContact.familyName,
        email: data.shippingContact.emailAddress,
        phone: data.shippingContact.phoneNumber,
        street: data.shippingContact.addressLines[0],
        postal_code: data.shippingContact.postalCode,
        city: data.shippingContact.locality,
        country: data.shippingContact.countryCode,
        latitude: null,
        longitude: null
      },
      event: {
        starttime: selectedDateTime?.toString()
      },
      place: place.current
    };

    console.log("Contact: ", contact);

    const response = await axios.post(API + '/sendAppointment', contact);
    console.log('Sent appointment: ', response);
    setLoading(false);
    const bok:BookingType = {
      gpid: place.current?.place_id,
      email: data.shippingContact.emailAddress,
      name: data.shippingContact.givenName + " " + data.shippingContact.familyName,
      starttime: selectedDateTime?.toString()
    };
    setSelectedBooking(bok);
  }

  React.useEffect(() => {
    // console.log("Apple place: ", selectedPlace);
    // console.log("Apple date: ", selectedDateTime);
    place.current = selectedPlace;

  }, [selectedPlace, selectedDateTime]);


  const showSnackbar = (msg:string) => () => {
    setState({ ...state, open: true, message: msg });
  };

  async function onApplePayButtonClicked() {

    console.log('onApplePayButtonClicked ');
    if (!appleActivated) {
      return;
    }

    // Define ApplePayPaymentRequest
    const request = {
      countryCode: "DE",
      currencyCode: "EUR",
      merchantIdentifier: "merchant.rocks.point",
      merchantCapabilities: [
          "supports3DS",
          "supportsCredit",
          "supportsDebit"
      ],
      supportedNetworks: [
          "visa",
          "masterCard",
          "amex",
          "discover"
      ],
      requiredShippingContactFields: [
        "postalAddress",
        "name",
        "phone",
        "email"
      ],
      total: {
        "label": "Book appointment for free",
        "type": "final",
        "amount": "0.00"
      }      
    };
  
    // Create ApplePaySession
    const session = new window.ApplePaySession(3, request);

    session.onvalidatemerchant = async (event:any) => {
      // Call your own server to request a new merchant session.
      const merchantSession = await validateMerchant(event.validationURL);
      console.log("onvalidatemerchant", event, merchantSession);
      session.completeMerchantValidation(merchantSession);
    };
  
    session.onpaymentauthorized = async (event:any) => {

        setLoading(true);
        console.log("onpaymentauthorized", event);
        // Define ApplePayPaymentAuthorizationResult
        const result = {
            "status": window.ApplePaySession.STATUS_SUCCESS
        };
        session.completePayment(result);

        await sendAppointment(event.payment);
    };
  
    session.oncancel = (event:any) => {
        // Payment canceled by WebKit
        console.log("oncancel", event);
    };
  
    session.begin();
  };

  async function activateApple () {

    await loadScript("https://applepay.cdn-apple.com/jsapi/1.latest/apple-pay-sdk.js", "apple-pay-api-script").then(async () =>  {

      if (!appleActivated) {

        const applePaySession: ApplePaySession = window.ApplePaySession;
        if (applePaySession && applePaySession.canMakePayments()) {
  
          setAppleActivated(true);
          showSnackbar("Apple Pay activated");
        }
        else if (applePaySession && !applePaySession.canMakePayments()) {

          console.log("This device is not capable of making Apple Pay payments");
          showSnackbar("This device is not capable of making Apple Pay payments");
          setAppleActivated(false);
        }
      }

      if (appleActivated && !buttonActivated.current) {

        const applbtn = document.getElementById('apple-pay-button');
        if (applbtn && (null === applbtn.onclick) && !buttonActivated.current) {
          buttonActivated.current = true;
          applbtn.addEventListener('click', onApplePayButtonClicked);
        }
      }
        
    });

    return true;
  };

  const act = activateApple();

  return (
    <div id="apple-pay-container" style={{ width: '100%', maxWidth: '240px' }} className="apple-pay-button" >
      {appleActivated && 
        <apple-pay-button 
          id="apple-pay-button" 
          buttonstyle="black" 
          type="book" 
          locale={i18n.resolvedLanguage} >
        </apple-pay-button>
      }

      <Box sx={{ width: 500 }}>
        <Snackbar
        anchorOrigin={{ vertical, horizontal  }}
        open={open}
        onClose={handleClose}
        message="I love snacks"
        key={'bottomCenter'}
        />
      </Box>

      {loading && 
        <Container maxWidth="lg" sx={{ mt: 6, mb: 6 }}>
          <CircularProgress
            color="primary"
            sx={{ position: "absolute", top: "50%", left: "50%" }}
          />
        </Container>    
      }

    </div>
  );
};

export default AppleCheckoutComponent;
